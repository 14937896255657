<template>
  <div class="courier-form">
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">
          {{ $t(`couriers.form.header.${courier ? 'edit' : 'new'}`) }}
        </p>
      </header>
      <section class="modal-card-body">
        <form ref="form">
          <div class="columns is-mobile">
            <div class="column">
              <b-field :label="$t('couriers.form.userGroups')"/>
              <courier-hub-user-group-selector
                :value.sync="form.userGroups"
                multiple
                :userGroups="hubsUserGroups"/>
            </div>
          </div>
          <div class="columns is-mobile">
            <div class="column is-half">
              <b-field :label="$t('couriers.form.name')">
                <b-input v-model="form.name"/>
              </b-field>
            </div>
            <div class="column is-half">
              <b-field :label="$t('couriers.form.email')">
                <b-input
                  v-model="form.email"
                  type="email"/>
              </b-field>
            </div>
          </div>
          <div class="columns is-mobile">
            <div class="column is-half">
              <b-field :label="$t('couriers.form.mobileNumber')">
                <b-input
                  v-model="form.mobileNumber"
                  type="tel"
                  pattern="[+]?[0-9]{8,}"
                  validationMessage="Please enter a valid mobile number"
                  title="Please enter a valid mobile number"/>
              </b-field>
            </div>
            <div class="column is-half">
              <b-field :label="$t('couriers.form.password')">
                <b-input
                  v-model="form.password"
                  type="password"/>
              </b-field>
            </div>
          </div>
        </form>
      </section>
      <footer class="modal-card-foot">
        <b-button
          type="is-primary"
          expanded
          @click="createUpdateAccount">
          {{ $t(`common.actions.${courier ? 'update' : 'create'}`) }}
        </b-button>
      </footer>
    </div>
  </div>
</template>

<script>
import { USER_ROLES } from '@js/constants'
import { mapGetters } from 'vuex'

const CourierHubUserGroupSelector = () => import('@components/CourierHubUserGroupSelector')

export default {
  name: 'courier-form',
  components: {
    CourierHubUserGroupSelector
  },
  props: {
    courier: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      form: {}
    }
  },
  computed: {
    ...mapGetters('hub', [
      'getHubUserGroups',
      'hubs'
    ]),
    hubsUserGroups() {
      return this.hubs.reduce((userGroups, hub) => {
        return userGroups.concat(this.getHubUserGroups(hub.id))
      }, [])
    }
  },
  created() {
    if (this.courier) {
      this.form = {
        type: USER_ROLES.COURIER,
        id: this.courier.id,
        name: this.courier.name,
        email: this.courier.email,
        mobileNumber: this.courier.mobileNumber,
        userGroups: this.courier.userGroupNames
      }
    } else {
      this.form = {
        type: USER_ROLES.COURIER
      }
    }
  },
  methods: {
    createUpdateAccount() {
      const form = this.$refs.form
      if (form.checkValidity()) {
        if (this.courier) {
          this.$emit('update', this.form)
        } else {
          this.$emit('create', this.form)
        }
      } else {
        form.requestSubmit()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.courier-form {
  form {
    padding-bottom: $space-l;
  }

  .field {
    margin-bottom: 0;

    &::v-deep {
      .label,
      .input {
        @extend %body;
      }

      .label {
        margin-bottom: $space-xxs;
      }
    }
  }

  .customer-care-selected-tip {
    margin-top: $space-xxs;
    @extend %small;
  }
}
</style>
